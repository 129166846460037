import Box, { BoxProps } from '@/components/ui/Box';
import { IMG_RATIO, ImgRatio } from '@/lib/constants';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, MaybeArrayOf, OverrideProps } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { FragmentType, gql } from '__generated__';
import classNames from 'classnames';
import Image, { ImageProps } from 'next/image';
import * as style from './ImageAsset.css';

export const IMAGE_ASSET_FRAGMENT = gql(`
  fragment ImageAsset on AssetInterface {
    __typename
    uid
    url
    height
    width
    alt
  }
`);

export type ImageAssetOwnProps = OverrideProps<
  Partial<ImageProps>,
  {
    ratio?: `${ImgRatio}`;
    wrapProps?: BoxProps;
  } & style.WrapVariants
>;

export type ImageAssetProps = ImageAssetOwnProps & {
  data?: MaybeImage;
};

export type ImageAssetData = FragmentType<typeof IMAGE_ASSET_FRAGMENT>;
export type MaybeImage = MaybeArrayOf<ImageAssetData>;

const ImageAsset = withFragmentArray(
  IMAGE_ASSET_FRAGMENT,
  (
    images,
    {
      wrapProps,
      round,
      tint,
      fit,
      fullHeight,
      layout,
      position,
      shadow,
      objectPosition,
      ...props
    }: ImageAssetOwnProps,
  ) => {
    const image = firstNonNullable(images);

    if (!image?.url) return null;

    const ratio = props.ratio ? IMG_RATIO[props.ratio] : null;
    const hasRatio = !!ratio;
    const imageFill = layout === 'fill' || layout === 'fillParent' || hasRatio;
    position = position ?? (layout === 'fillParent' ? 'absolute' : 'relative');
    layout = layout ?? (imageFill ? 'fill' : 'responsive');

    const showOverlay = !!tint;
    // const hasPosition = wrapProps?.sx?.position;

    const wrapped = true; // hasRatio || wrap || round || tint || fit;
    const className = classNames(
      props.className,
      style.image({
        responsive: layout === 'responsive',
      }),
    );

    if (wrapped) {
      return (
        <Box
          {...wrapProps}
          style={{
            ...assignInlineVars({
              ...(hasRatio && { [style.imageAssetVars.ratio]: `${ratio}` }),
            }),
          }}
          className={classNames(
            wrapProps?.className,
            style.wrap({
              round,
              tint,
              fit,
              hasRatio,
              layout,
              position,
              fullHeight,
              objectPosition,
              shadow,
            }),
          )}>
          <Image
            {...{
              src: image.url,
              alt: image.alt ?? '',
              ...(imageFill
                ? {
                    fill: true,
                    sizes: '100vw',
                  }
                : {
                    height: image.height ?? 100,
                    width: image.width ?? 100,
                  }),
              ...props,
              className,
            }}
          />
          {showOverlay && <div className={style.overlay} />}
        </Box>
      );
    }

    return (
      <Image
        {...{
          src: image.url,
          alt: image.alt ?? '',
          // width: image.width ?? 100,
          // height: image.height ?? 100,
          layout: 'fill',
          objectFit: 'cover',
          // objectPosition: 'center',
          ...props,
          className,
        }}
      />
    );
  },
);

export default ImageAsset;

import classNames from 'classnames';
import Box, { BoxProps } from '../Box';
import * as style from './Container.css';

export type ContainerProps<T extends React.ElementType = 'div'> = BoxProps<
  T,
  style.ContainerVariants
>;

const Container = <T extends React.ElementType = 'div'>({
  maxWidth,
  disablePadding,
  ...props
}: ContainerProps<T>) => {
  return (
    <Box
      {...props}
      className={classNames(props.className, style.root({ maxWidth, disablePadding }))}
    />
  );
};

export default Container;

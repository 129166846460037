import "../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2FthemeVars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8WWz3LaMBDG73kKzfgCU9BoV%2F%2FVUwHzHoSSJi2klLaQtpN379jG8q7tyZGMT%2F6s1U%2FfaleW3O2NPr4o8e9OiPm8eYMk1EfyjkmY4wtVdBKBKyaJw9PzZLvfHI4TJe1pd5iJ7Wa%2FnSiJYDRgJYkPQkkb7fkynQklfaVNZ2I0SvsuQqnzI4mYUrLlZM8mMW4UDZzLYxwOwDCgOkoFhsRg8xQgIaoGKZlZFuLzaNUC5dCoZ0ierDgORYakISBxAMUBMlAkXjOTZ8hAlCroGqi5y14ISgidV28brM5eX%2B9kw42sHje3z%2FV9EoWCoMOSqttKVQu1iFT9XKvGWaDqLomidOWqLKn6kESxXJauDFT9kkQR43K1YjM8JlE4v7CLT1R9quYNpSuRql%2BTKNaqLEs29lsSxWJVPVTdV2PXayodagus6Z8rjlHWszV9Z1uBtKLmIH2wLm8wRukdNmXIt4OFuVyIaLtKHO7IkZKNvHJatpIhtzmANDbU4MgLoR%2BGEruwWNVqTY8jBfGD4jUjsyL0EkzTCo6SNe2b61CX7boB7sQbj1vV1udVWxltk%2BRe4432epfhocOfDMmASPfV5G01jEh47cAWZgawX%2FwsQ24QPMkoyuhdU0a9tmZhih5H7bmNIzv5%2B%2FanyZm7Bb5uA8qRY9GEcMX6nt1eHKl4ibalj%2FwhL%2B%2BX7JfbJ%2FvPu9wH%2Ft7eKCjuNPSsVl67O1SI%2Bsrlp%2FEgrqtFlX%2FRbRDnQxKA%2FK8BmMR5c5rkCwSP0G9%2BNW9%2BtaleENNcpVXZZapPApQ6X5gYGvGRiTEJb%2FlFFjb1wJ54n4Srtdf%2FFQeqPTILAAA%3D%22%7D"
export var appVars = {zIndex:{nav:'var(--el43px11)'},margin:{x:'var(--el43px12)',y:'var(--el43px13)'},gutter:'var(--el43px14)',ratio:{max:'var(--el43px15)',min:'var(--el43px16)'},root:{width:'var(--el43px17)',height:'var(--el43px18)'},nav:{height:'var(--el43px19)',heightLg:'var(--el43px1a)',fabSize:'var(--el43px1b)'}};
export var baseUnitVar = 'var(--el43pxa)';
export var colorVars = {primary:'var(--el43pxb)',primaryLight:'var(--el43pxc)',primaryDark:'var(--el43pxd)',primary05:'var(--el43pxe)',primary20:'var(--el43pxf)',primary40:'var(--el43pxg)',primary60:'var(--el43pxh)',warmGrey200:'var(--el43pxi)',warmGrey100:'var(--el43pxj)',neutralGrey:'var(--el43pxk)',white:'var(--el43pxl)',black:'var(--el43pxm)',danger:'var(--el43pxn)'};
export var fontSizeVars = {poster:'var(--el43pxo)',h1:'var(--el43pxp)',h2:'var(--el43pxq)',h3:'var(--el43pxr)',h4:'var(--el43pxs)',h5:'var(--el43pxt)',h6:'var(--el43pxu)',body:'var(--el43pxv)',large:'var(--el43pxw)',small:'var(--el43pxx)',tiny:'var(--el43pxy)',button:'var(--el43pxz)',buttonSm:'var(--el43px10)'};
export var ratioMaxInt = 0.75;
export var ratioMinInt = 0.5625;
export var rootTheme = 'el43px0 el43px9';
export var spacingVars = {'0':'var(--el43px1)','4':'var(--el43px2)','8':'var(--el43px3)','12':'var(--el43px4)','16':'var(--el43px5)','24':'var(--el43px6)','32':'var(--el43px7)','56':'var(--el43px8)'};
export var theme = 'el43px9';
export var themeSpacing = 'el43px0';
export var themeVars = {baseUnit:'var(--el43pxa)',palette:{colors:colorVars},typography:{size:fontSizeVars},zIndex:{nav:'var(--el43px11)'},margin:{x:'var(--el43px12)',y:'var(--el43px13)'},gutter:'var(--el43px14)',ratio:{max:'var(--el43px15)',min:'var(--el43px16)'},root:{width:'var(--el43px17)',height:'var(--el43px18)'},nav:{height:'var(--el43px19)',heightLg:'var(--el43px1a)',fabSize:'var(--el43px1b)'}};
import { bodyFontWeightBold, headingFontWeightBold } from '@/theme/mui/muiTheme';
import { Typography, TypographyProps, TypographyTypeMap } from '@mui/material';

type TxtProps<
  RootComponent extends React.ElementType = TypographyTypeMap['defaultComponent'],
  AdditionalProps = {},
> = TypographyProps<
  RootComponent,
  {
    bold?: boolean;
    autoMargin?: boolean;
  } & AdditionalProps
>;

export type { TxtProps };

export const headingVariants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'poster'];

const Txt = <RootComponent extends React.ElementType>(props: TxtProps<RootComponent>) => {
  const { bold, autoMargin, ...rest } = props;

  const isHeading = [...headingVariants].includes(rest.variant as string);

  return (
    <Typography
      {...{
        ...rest,
        sx: {
          ...(bold && { fontWeight: isHeading ? headingFontWeightBold : bodyFontWeightBold }),
          ...(autoMargin && { marginBottom: 'min(0.8em, 40px)' }),
          ...rest.sx,
        },
      }}
    />
  );
};

export default Txt;
